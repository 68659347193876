<template>
  <cz-dialog
    :width="500"
    v-on="$listeners"
    v-bind="$attrs"
    :title="title || $t('customer.userManagement.assignUserRoleTitle')"
  >
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-form
        class="pa-4"
        @submit.prevent="$emit('done', form.role, user)"
        :disabled="loading"
        v-if="user"
      >
        <cz-form-field :label="$t('customer.userManagement.assignRoleFor')">
          <div>{{ user.fullName }}</div>
        </cz-form-field>

        <cz-form-field
          required
          :label="$t('customer.userManagement.role')"
          class="mt-4"
        >
          <validation-provider
            rules="required"
            :name="$t('customer.userManagement.role')"
            v-slot="{ errors }"
          >
            <cz-autocomplete
              v-model="form.role"
              dense
              :items="filteredRoles"
              :error-messages="errors"
              :placeholder="$t('customer.userManagement.assignRolePlaceholder')"
            ></cz-autocomplete>
          </validation-provider>
        </cz-form-field>

        <div class="d-flex align-center mt-6" style="column-gap: 15px">
          <cz-button
            color="info"
            type="submit"
            :disabled="invalid"
            large
            :loading="loading"
            >{{ $t('customer.userManagement.inviteUser') }}</cz-button
          >
          <div
            class="negative--text text-subtitle-2 font-weight-semibold"
            v-if="errorMessage"
          >
            {{ errorMessage }}
          </div>
        </div>
      </v-form>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import { CzDialog, CzFormField, CzAutocomplete, CzButton } from '@/components';
import RoleType from '@/shared/types/RoleType';
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'AssignRoleDialog',
  components: {
    CzDialog,
    CzFormField,
    CzAutocomplete,
    CzButton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      required: true
    },

    errorMessage: {
      type: String
    },
    title: {
      type: String
    }
  },
  computed: {
    filteredRoles() {
      const _roleTypes = cloneDeep(this.roleTypes);
      return _roleTypes.filter((item) => !this.userRoles.includes(item.value));
    },
    userRoles() {
      return this.user?.roles?.map((item) => item.role);
    },
    roleTypes() {
      const roleTypes = [
        {
          text: this.$t('customer.userManagement.roles.customerAdmin'),
          value: RoleType.CUSTOMER_ADMIN
        },
        {
          text: this.$t('customer.userManagement.roles.customerPurchaser'),
          value: RoleType.CUSTOMER_PURCHASER
        },
        {
          text: this.$t('customer.userManagement.roles.customerAuthorizer'),
          value: RoleType.CUSTOMER_AUTHORIZER
        },
        {
          text: this.$t('customer.userManagement.roles.customerObserver'),
          value: RoleType.CUSTOMER_OBSERVER
        },
        {
          text: this.$t('customer.userManagement.roles.customerCatalogAdmin'),
          value: RoleType.CUSTOMER_CATALOG_ADMIN
        },
        {
          text: this.$t('customer.userManagement.roles.customerAccountManager'),
          value: RoleType.CUSTOMER_ACCOUNT_MANAGER
        },
        {
          text: this.$t(
            'customer.userManagement.roles.customerDigitalArchiveViewer'
          ),
          value: RoleType.CUSTOMER_DIGITAL_ARCHIVE_VIEWER
        }
      ];

      const index = this.userRoles?.findIndex((role) =>
        [RoleType.CUSTOMER_PURCHASER, RoleType.CUSTOMER_AUTHORIZER].includes(
          role
        )
      );
      if (index !== -1) {
        roleTypes.push({
          text: this.$t('customer.userManagement.roles.digitalArchiveSigner'),
          value: RoleType.CUSTOMER_DIGITAL_ARCHIVE_SIGNER
        });
      }

      return roleTypes;
    }
  },
  data() {
    return {
      form: {
        role: ''
      }
    };
  }
};
</script>

<style></style>
